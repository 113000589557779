.homeWrapper{
    background-color: #ECFEEA;
    min-height: 100vh;
    display: flex;
    width: calc(100vw - 20px);
    flex-direction: column;
    padding:10px;
}

.homeForms{
    display: flex;
    margin: 0 auto 0 auto;
    padding-top: 100px;
    flex-direction: column;
}

.recentTransactions{
    display: flex;
    flex-direction: column;
}